<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
              <li class="flex-fill p-0">
                <a class="nav-link" :class="!openAbout && !openContact ? 'active' : ''" data-toggle="pill" href="#profile">
                  {{cvProfileInfo}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link" :class="openAbout ? 'active' : ''" data-toggle="pill" href="#about">
                  {{cvAbout}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link" :class="openContact ? 'active' : ''" data-toggle="pill" href="#contactsocial">{{cvContact}}</a>
              </li>
              <li class="flex-fill p-0" v-if="checkClubRoleAccess()">
                <a class="nav-link" data-toggle="pill" href="#edit-roles">
                  {{ cvEditRoles }}
                </a>
              </li>
              <li class="flex-fill p-0" v-if="checkClubRoleAccess()">
                <a class="nav-link" data-toggle="pill" href="#manage-users">
                  {{ cvManageUsers }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-edit-list-data iq-card">
        <div class="tab-content">
          <div class="tab-pane fade" :class="!openAbout && !openContact ? 'active show' : ''" id="profile" role="tabpanel">
            <b-card class="mb-4 p-3">
              <div class="">
                <h4>
                  {{ cvClubNameLabel }}
                </h4>
                <ValidationObserver ref="clubForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider
                        name="Club Name"
                        rules="required|max:50"
                        v-slot="{ errors }">
                        <input v-model="vmClubFormData.club_name" type="text" class="form-control" :placeholder="cvClubNameLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider><br/>
                      <div>
                        <input v-model="plus_or_not" type="checkbox" name="gideplus" style="margin-right:5px" />
                        <label for="gideplus" style="color:red">Is this Club Gide Plus ?</label>
                      </div>
                      <button type="button" class="btn-primary border-0 px-3 py-2 rounded mt-3" @click="clubEdit(true)">
                        Save
                      </button>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
              <div>
                <h4>
                  Profile Pic
                </h4>
                <hr>
                <b-form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 150px; width: 150px; border-radius: 50%; ">
                        <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden; border-radius: 50%; " />
                        <i v-else class="fas fa-users d-flex justify-content-center align-items-center rounded-circle " style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" style="cursor:pointer;"></i>
                          <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;  pointer-events: none;" @change="openImageDialog($event, 'Profile Pic')" />
                        </label>
                      </div>
                    </div>
                    <span class="text-danger" ref="proPicError"></span>
                  </div>
                  <button type="button" class="p-2 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageEdit('Profile Pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Save Profile Pic</span>
                  </button>
                  <button v-if="vmProfilePic" type="button" class="p-2 ml-3 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('profile_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Remove Profile Pic</span>
                  </button>
                </b-form>
              </div>
              <div class="mt-4">
                <h4>
                  Cover Pic
                </h4>
                <hr>
                <b-form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 241px; width: 100%;">
                        <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden;" />
                        <i v-else class="fas fa-users d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" style="cursor:pointer;"></i>
                          <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;  pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                        </label>
                      </div>
                    </div>
                    <span class="text-danger" ref="coverPicError"></span>
                  </div>
                  <button type="button" :disabled="cvCoverPicError || cvCoverPicLoading" class="p-2 rounded btn btn-primary border-0 " @click="imageEdit('Cover Pic')" >
                    <b-spinner small class="ml-4 mr-4" v-if="cvCoverPicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else> Save Cover Pic</span>
                  </button>
                  <button v-if="vmCoverPic" type="button" class="p-2 ml-3 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('cover_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelCoverPicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Remove Cover Pic</span>
                  </button>
                </b-form>
              </div>
            </b-card>
          </div>
          <div class="tab-pane fade" :class="openAbout ? 'active show' : ''" id="about" role="tabpanel">
            <b-card class="mb-4 px-3 pb-3">
              <div class="mt-4 ">
                <b-form>
                  <div>
                    <h4>
                      About Details
                    </h4>
                    <hr>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="validation_desc" >{{cvClubDescLabel}}</label>
                        <vue-editor v-model="vmClubFormData.club_desc">
                        </vue-editor>
                        <!-- <b-form-textarea v-model="vmClubFormData.club_desc" style="line-height: 24px;" :placeholder="cvClubDescLabel" rows="2"  max-rows="6"  required ></b-form-textarea> -->
                      </div>
                    </div>
                  </div>
                  <button class="btn-primary border-0 px-3 py-2 rounded ml-2 float-right" @click="goToClub()" >
                    View Club
                  </button>
                  <button v-if="openAbout" class="btn-primary border-0 px-3 py-2 rounded ml-2 float-right" @click="goToClubContact()" >
                    Next
                  </button>
                  <button type="button" class="btn-primary border-0 px-3 py-2 rounded float-right" :disabled="cvLoadingStatus" @click="clubEdit()">
                    <b-spinner small class="ml-4 mr-4" v-if="cvLoadingStatus" label="Spinning">
                    </b-spinner>
                    <span v-else>Save</span>
                  </button>
                </b-form>
              </div>
            </b-card>
          </div>
          <div class="tab-pane fade" :class="openContact ? 'active show' : ''" id="contactsocial" role="tabpanel">
            <b-card class="mb-4 p-3">
                <b-form>
                  <div>
                    <h4>
                      Website and Social Links
                    </h4>
                    <hr />
                    <div class="col-md-12 mb-3">
                      <label for="validation_website" >Website</label>
                      <input v-model.trim="vmSocialLinkFormData.social_links.website" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validation_website" >Facebook</label>
                      <input v-model.trim="vmSocialLinkFormData.social_links.facebook" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validation_website" >Twitter</label>
                      <input v-model.trim="vmSocialLinkFormData.social_links.twitter" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validation_website" >Instagram</label>
                      <input v-model.trim="vmSocialLinkFormData.social_links.instagram" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validation_website" >Youtube</label>
                      <input v-model.trim="vmSocialLinkFormData.social_links.youtube" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validation_website" >Linkedin</label>
                      <input v-model.trim="vmSocialLinkFormData.social_links.linkedin" type="text" class="form-control" />
                    </div>
                  </div>
                  <button type="button" class="btn-primary border-0 px-3 py-2 rounded" :disabled="cvSocialLinkLoading" @click="socialLinksEdit()">
                    <b-spinner small class="ml-4 mr-4" v-if="cvSocialLinkLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Save</span>
                  </button>
                  <button class="btn-primary border-0 px-3 py-2 rounded ml-2" @click="goToClub()">
                    View Club
                  </button>
                </b-form>
            </b-card>
          </div>
          <div class="tab-pane fade" id="edit-roles" role="tabpanel">
            <b-card>
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">
                    {{ cvEditRoles }}
                  </h4>
                </div>
              </div>
              <hr class="m-0" />
              <div class="iq-card-body px-0 p-3 pt-0">
                <RoleList :propModuleName="propModuleName" />
              </div>
            </b-card>
          </div>
          <div class="tab-pane fade" id="manage-users" role="tabpanel">
            <b-card>
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title w-100">
                  <h4 class="card-title">
                    {{ cvManageUsers }}
                  </h4>
                </div>
              </div>
              <hr class="m-0" />
              <div class="iq-card-body p-3 pt-2">
                <UserList :propModuleName="propModuleName" />
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<style scoped lang="scss">
@media (max-width: 479px) {
  .profile-feed-items li{
    margin: 0px !important;
  }
}
</style>
<script>
import { ValidationProvider } from "vee-validate"
import { socialvue } from "../../../../config/pluginInit"
import { Clubs } from "../../../../FackApi/api/club.js"
import { Images } from "../../../../FackApi/api/image.js"
import { SocialLinks } from "../../../../FackApi/api/socialLink.js"
import ApiResponse from "../../../../Utils/apiResponse.js"
import RoleList from "../../Role/RoleList.vue"
import UserList from "../../../User/UserList.vue"
import CropImage from "../../../../components/cropImage.vue"
import { VueEditor } from "vue2-editor"
import userPermission from "../../../../Utils/user_permission"

export default {
  name: "ProfileEdit",
  components: {
    ValidationProvider,
    RoleList,
    UserList,
    CropImage,
    VueEditor
  },
  data () {
    return {
      apiName: "club_edit",
      cvLoadingStatus: false,
      cvSelectLabel: "Select ",
      cvProfileInfo: "Profile",
      cvAbout: "About",
      cvContact: "Contact",
      cvEditRoles: "Manage Roles",
      cvManageUsers: "Manage Users",
      cvCardTitle: "Edit Club",
      cvCardSubHeader: "Edit Club ",
      cvSubmitBtn: "Edit",
      cvClubNameLabel: "Name",
      cvClubDescLabel: "Description",
      cvClubProfilePic: "Profile Pic",
      cvClubCoverPic: "Cover Pic",
      showToast: false,
      showModelAskOtpBox: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Club",
      cvSocialLinkLoading: false,
      cvProfilePicError: false,
      cvCoverPicError: false,
      cvProfilePicLoading: false,
      cvCoverPicLoading: false,
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 4 mb.",
      cvDelCoverPicLoading: false,
      cvDelProfilePicLoading: false,
      vmClubFormData: {},
      vmClubProPicImage: Object.assign({}, this.initImageData()),
      vmClubCoverPicImage: Object.assign({}, this.initImageData()),
      vmProfilePic: "",
      vmCoverPic: "",
      vmClubProfileImage: Object.assign({}, this.initImageData()),
      vmSocialLinkFormData: Object.assign({}, this.initSocialLinks()),
      cvCountryOptions: [],
      cvCountrySelectValue: {},
      orgObj: {},
      propModuleName: "CLUB",
      profilePicObj: {},
      coverPicObj: {},
      propCropImageDialogOpen: false,
      fileSrc: "",
      updateImageType: null,
      propStencilSize: null,
      propCropDialogSize: "md",
      openAbout: !!(this.$route.query && this.$route.query.about),
      openContact: !!(this.$route.query && this.$route.query.contact),
      plus_or_not: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * checkClubRoleAccess
     */
    checkClubRoleAccess () {
      // We are not adding hierarchy so we will show roles tabs only to univ admin
      if (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118") {
        return true
      }
      else if (this.userData.user_role === "USERROLE11115" && this.userData.roles[this.vmClubFormData.owner_module_obj_id]) {
        // Univ Admin
        return true
      }
      else if (this.userData.user_role === "USERROLE11116" && this.userData.roles[this.vmClubFormData.owner_module_obj_id]) {
        // Org Admin
        return true
      }
      else {
        return false
      }
    },

    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.$route.params.club_id,
        module_name: "CLUB",
        image: [],
        image_type: {}
      }
    },
    /** */
    initSocialLinks () {
      return {
        module_id: this.$route.params.club_id,
        module_name: "CLUB",
        social_links: {
          website: "",
          facebook: "",
          twitter: "",
          instagram: "",
          youtube: "",
          linkedin: ""
        }
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.clubView()
      this.imageGetProfileImages()
      this.socialLinksView()
    },
    /**
     * clubView
     */
    async clubView () {
      try {
        let clubId = this.$route.params.club_id
        let clubViewResp = await Clubs.clubView(this, clubId)
        if (clubViewResp && !clubViewResp.resp_status) {
          return false
        }
        else {
          this.clubObj = clubViewResp.resp_data.data

          if (this.clubObj.is_gide_plus === 0) {
            this.plus_or_not = false
          }
          else {
            this.plus_or_not = true
          }

          this.vmClubFormData = this.clubObj
          await this.assignClubRoleToTheUser()
        }
      }
      catch (err) {
        console.error("Exception occurred at clubView() and Exception:", err.message)
      }
    },
    /**
     * clubEdit
     */
    async clubEdit (saveName) {
      try {
        this.cvLoadingStatus = true
        let formData = { }
        if (saveName) {
          formData = { club_id: this.vmClubFormData.club_id, club_name: this.vmClubFormData.club_name }
        }
        else {
          formData = JSON.parse(JSON.stringify(this.vmClubFormData))
          delete formData.club_name
        }

        delete formData.user_id
        formData.club_module_obj_id = this.vmClubFormData.club_id

        if (this.plus_or_not === false) {
          formData.is_gide_plus = 0
        }
        else {
          formData.is_gide_plus = 1
        }

        if (formData.club_desc && formData.club_desc.length < 50) {
          // Empty of less than 100 char not allowed
          this.toastMsg = "Club description cannot be empty or have less than 50 characters"
          this.showToast = true
          this.toastVariant = "danger"
          return
        }

        let clubEditResp = await Clubs.clubEdit(this, formData)
        await ApiResponse.responseMessageDisplay(this, clubEditResp)
        if (clubEditResp && !clubEditResp.resp_status) {
          return false
        }
        else {
          if (this.$route.query && this.$route.query.about) {
            await this.$router.push("/club_edit/" + this.$route.params.club_id + "?contact=true")
            this.openAbout = false
            this.openContact = true
          }
        }
        await this.addClubToModules()
        //  Emit if opened in Modal
      }
      catch (err) {
        console.error("Exception occurred at clubEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * addClubToModules
     */
    async addClubToModules () {
      let userData = JSON.parse(JSON.stringify(this.userData))
      if (!userData.modules) {
        userData.modules = {}
      }
      if (!userData.modules.clubs) {
        userData.modules.clubs = {}
      }
      userData.modules.clubs[this.clubObj["club_id"]] = this.clubObj
      userData.modules.clubs[this.clubObj["club_id"]]["profile_pic"] = this.profilePicObj
      userData.modules.clubs[this.clubObj["club_id"]]["cover_pic"] = this.coverPicObj
      await this.$store.dispatch("User/setUserProfileAction", JSON.parse(JSON.stringify(userData)))
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "Profile Pic" && this.vmClubProPicImage.image.length === 0) {
        return false
      }
      if (imageType === "Cover Pic" && this.vmClubCoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = true
          image = this.vmClubProPicImage
        }
        else {
          this.cvCoverPicLoading = true
          image = this.vmClubCoverPicImage
        }
        if (image && Object.keys(image).length > 0 && image.image.length > 0) {
          const imageUpdateResp = await Images.imageProfileUpdate(this, image)
          await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
          if (imageUpdateResp && !imageUpdateResp.resp_status) {
            return false
          }
          this.vmClubProPicImage = Object.assign({}, this.initImageData())
        }
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = false
        }
        else {
          this.cvCoverPicLoading = false
        }
      }
    },
    /**
     * imageRemove
     */
    async imageRemove (imgType) {
      if (imgType === "profile_pic") {
        this.cvDelProfilePicLoading = true
      }
      else {
        this.cvDelCoverPicLoading = true
      }
      let img = imgType === "profile_pic" ? 2 : 3
      const imageUpdateResp = await Images.imageDelete(this, this.clubObj["club_id"], img)
      await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
      if (imageUpdateResp && !imageUpdateResp.resp_status) {
        return false
      }
      if (imgType === "profile_pic") {
        this.vmProfilePic = ""
        this.cvDelProfilePicLoading = false
      }
      else {
        this.vmCoverPic = ""
        this.cvDelCoverPicLoading = false
      }
    },
    /**
     * assignClubRoleToTheUser
     */
    async assignClubRoleToTheUser () {
      let userData = JSON.parse(JSON.stringify(this.userData))
      if (!userData.modules) {
        userData.modules = {}
      }
      if (!userData.modules.clubs) {
        userData.modules.clubs = {}
      }
      if (!userData.roles) {
        userData.roles = {}
      }
      userData.roles[this.clubObj["club_id"]] = {
        "actions": [],
        "mod_obj_id": this.clubObj["club_id"],
        "udr_name": "Admin"
      }
      userData.modules.clubs[this.clubObj["club_id"]] = this.clubObj
      await this.$store.dispatch("User/setUserProfileAction", JSON.parse(JSON.stringify(userData)))
    },
    /**
     * imageGetProfileImages()
     */
    async imageGetProfileImages () {
      try {
        let clubId = this.$route.params.club_id
        let imageProfileResp = await Images.imageListProfileImages(this, clubId)
        if (imageProfileResp && !imageProfileResp.resp_status) {
          return false
        }
        else {
          // let profileImageData = imageProfileResp.resp_data.data
          this.vmProfilePic = imageProfileResp.resp_data.profile_pic ? imageProfileResp.resp_data.profile_pic.image_thumbnail : ""
          this.vmCoverPic = imageProfileResp.resp_data.cover_pic ? imageProfileResp.resp_data.cover_pic.image : ""
        }
      }
      catch (err) {
        console.error("Exception occurred at imageGetProfileImages() and Exception:", err.message)
      }
    },
    /**
     * socialLinksView()
     */
    async socialLinksView () {
      try {
        this.cvLoadingStatus = true
        let clubId = this.$route.params.club_id
        let socialLinkViewResp = await SocialLinks.socialLinkView(this, clubId)
        if (socialLinkViewResp && !socialLinkViewResp.resp_status) {
          return false
        }
        else {
          this.vmSocialLinkFormData.social_links = JSON.parse(socialLinkViewResp.resp_data.data.social_links)
        }
      }
      catch (err) {
        console.error("Exception occurred at socialLinksView() and exception", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * socialLinksEdit
     */
    async socialLinksEdit () {
      if (Object.values(this.vmSocialLinkFormData.social_links).filter(Boolean).length < 1) {
        return
      }
      try {
        this.cvSocialLinkLoading = true
        const socialLinksEditResp = await SocialLinks.socialLinkProfileUpdate(this, this.vmSocialLinkFormData)
        await ApiResponse.responseMessageDisplay(this, socialLinksEditResp)
        if (socialLinksEditResp && !socialLinksEditResp.resp_status) {
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at socialLinksEdit() and exception", err.message)
      }
      finally {
        this.cvSocialLinkLoading = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
    * onChangeProfileImage
    */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmClubProPicImage = Object.assign({}, this.initImageData())
        this.vmClubProPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmClubProPicImage.image_type[orgImg.name] = 2
      }
      else {
        this.vmClubCoverPicImage = Object.assign({}, this.initImageData())
        this.vmClubCoverPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmClubCoverPicImage.image_type[orgImg.name] = 3
      }
    },
    /**
     * goToClub
     */
    goToClub () {
      try {
        this.$router.push(`/club/${this.$route.params.club_id}`)
      }
      catch (err) {
        console.error("Exception occurred at goToClub() and Exception:", err.message)
      }
    },
    /**
     * goToUnivContact
     */
    async goToClubContact () {
      try {
        await this.$router.push("/club_edit/" + this.$route.params.club_id + "?contact=true")
        this.openAbout = false
        this.openContact = true
      }
      catch (err) {
        console.error("Exception occurred at goToUnivContact() and Exception:", err.message)
      }
    }
  }
}
</script>
